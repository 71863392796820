import React from "react"
import { Formik, Form } from "formik"

import FormInput from "../../Elements/Form/FormInput"
import FormCheckbox from "../../Elements/Form/FormCheckbox"
import ErrorMessage from "elements/ErrorMessage"

import { hasIncompleteValues } from "../services/form"
import { generateWhereDidYouHearAboutUsSummaryAnswer } from "../services/enrollment"

const PatientEnrollmentFeedbackSummary = ({ patientEnrollment }) => {
  if (
    hasIncompleteValues({
      data: patientEnrollment,
      flow: "patientEnrollmentFeedback",
    })
  ) {
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )
  }

  return (
    <>
      <div>
        <p className="has-text-grey">
          On a scale of 1 to 10, how satisfied are you with the enrollment
          process?
        </p>
        <p className="has-text-weight-bold">
          {patientEnrollment.satisfactionScore.value} / 10
        </p>
      </div>
      <div>
        <p className="has-text-grey">
          What improvements would you suggest for the CarePlus enrollment
          process?{" "}
        </p>
        <p className="has-text-weight-bold">
          {patientEnrollment.whatImprovementsWouldYouSuggestPatientEnrollment}
        </p>
      </div>
    </>
  )
}

export default PatientEnrollmentFeedbackSummary
